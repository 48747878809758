<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-form>
        <br /><br />
        <b-card-code title=" الوثائق الخاصة بالفرد">
          <div>
            <b-row v-for="(document, index) in person" :key="index">
              <b-col md="6" xl="4">
                <b-form-group label-for="cardNumber">
                  <label> نوع الوثيقة</label>
                  <h5 class="text-capitalize mb-75">
                    {{ document.document_type.name }}
                  </h5>
                </b-form-group>
              </b-col>
              <b-col md="6" xl="4">
                <b-form-group label-for="number">
                  <label> رقم الوثيقة</label>
                  <h5 class="text-capitalize mb-75">
                    {{ document.number }}
                  </h5>
                </b-form-group>
              </b-col>

              <b-col md="6" xl="4">
                <!-- <validation-provider #default="{ errors }" name="daisp" :rules="rules"> -->
                <b-form-group label-for="datea">
                  <label> تاريخ الأصدار</label>
                {{document.release_date}}
                </b-form-group>
                <!-- <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider> -->
              </b-col>
              <b-col md="6" xl="4">
                <!-- <validation-provider #default="{ errors }" name="dateDisp" rules="required"> -->
                <b-form-group label-for="date">
                  <label> تاريخ الأنتهاء</label>
                 {{document.expiration_date}}
                </b-form-group>
                <!-- <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider> -->
              </b-col>
              <b-col md="12" xl="4">
                <b-form-group label-for="delimiter">
                  <label>الملاحظات</label>
                  <h5 class="text-capitalize mb-75">
                    {{ document.notes }}
                  </h5>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </b-card-code>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
// import FormTextAreaDefault from '..\form-textarea\FormTextArea.vue'
import vSelect from "vue-select";

import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BRow, BCol, BFormGroup, BInputGroupPrepend, BInputGroup } from "bootstrap-vue";

import {
  BFormDatepicker,
  BFormInput,
  BFormTextarea,
  BFormFile,
  BForm,
  BButton,
  BImg,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
// import '@core/scss/vue/libs/vue-flatpicker.scss';
import "flatpickr/dist/flatpickr.css";
import { ValidationProvider, ValidationObserver } from "vee-validate";
export default {
  name:"personDocument",
  components: {
    BCardCode,

    BForm,

    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,

    BImg,
    BButton,
    BFormInput,
    BFormFile,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BFormDatepicker,

    BFormTextarea,
    vSelect,
    BRow,

    BCol,
  },
  props: {
    person: {
         type: Array,
      default: () => [],
    },
  },
};
</script>

<style scoped>
label {
  color: #7e7a9f;
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.btn-purple {
  color: white;
  background-color: #00468e !important;
}
.align-left {
  left: 50px;
}

.but {
  top: 40px;
}
</style>
