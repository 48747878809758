<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-form>
        <b-card-code title=" الخدمات الخاصة ب الفرد">
          <b-row v-for="(serv, index) in personservice" :key="index">
            <b-col md="6" xl="4">
              <b-form-group label-for="cardNumber">
                <label> اسم الخدمة </label>

                <h5 class="text-capitalize mb-75">
                    {{ serv.proposal_service.name }}
                </h5>
              </b-form-group>
            </b-col>

            <b-col md="12" xl="4">
              <b-form-group label-for="delimiter">
                <label>الملاحظات</label>
                <h5 class="text-capitalize mb-75">
                  {{ serv.notes }}
                </h5>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-code>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BRow, BCol, BFormGroup, BInputGroupPrepend, BInputGroup } from "bootstrap-vue";

import {
  BFormDatepicker,
  BFormInput,
  BFormTextarea,
  BFormFile,
  BForm,
  BButton,
  BImg,
  BFormInvalidFeedback,
} from "bootstrap-vue";

import { ValidationProvider, ValidationObserver } from "vee-validate";
export default {
  components: {
    BCardCode,

    BForm,

    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,

    BImg,
    BButton,
    BFormInput,
    BFormFile,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BFormDatepicker,

    BFormTextarea,

    BRow,

    BCol,
  },
  props: {
    personservice: {
      type: Array,
      default: () => [],
    },
  },

};
</script>

<style scoped>
label {
  color: #7e7a9f;
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.btn-purple {
  color: white;
  background-color: #00468e !important;
}
.align-left {
  left: 50px;
}

.but {
  top: 40px;
}
</style>
